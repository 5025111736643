<template>
  <div class="activity-container main-cnt">
    <div class="title">活动列表</div>
    <div class="content">
      <common-table
        ref="tableRef"
        tableHeight="calc(100vh - 320px)"
        :ischeck="false"
        :filters="filters"
        :ispaging="true"
        :apiName="VipApi.vipActivityList"
        :columns="tableColumns"
        @edit="openDialog"
        @vipClick="openWillNumDialog"
        @albumClick="albumClick"
        @statusChange="changeActivityStatus"
        @projectChange="projectChange"
        @verifier="openVerifier"
      >
        <template #operate>
          <el-button
            type="primary"
            round
            @click="openDialog"
            v-if="authData.indexOf('n_I4qTN4kufPaMoJwmsV1Quzugh1gv') != -1"
          >
            <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
            新增活动</el-button
          >
        </template>
        <template #refund_status="{ row }" >
          <!-- 有权限 -->
          <template v-if="authData.indexOf('n_VFHeVOOCCVusB7eVFYGt0KcCbn') != -1">
            <el-popconfirm :title="`确定要${row.ma_refund_status == 2 ? '关闭' : '开启'}退款审核吗?`" @confirm="refundStatusChange(row)">
              <template #reference>
                <el-switch
                  v-model="row.ma_refund_status"
                  inline-prompt
                  active-text="开"
                  inactive-text="关"
                  :width="45"
                  :active-value="2"
                  :inactive-value="1"
                  :before-change="() => false"
                />
              </template>
            </el-popconfirm>
          </template>
          <!-- 没有权限 -->
          <template v-else>
            <el-switch
              v-model="row.ma_refund_status"
              inline-prompt
              active-text="开"
              inactive-text="关"
              :width="45"
              :active-value="2"
              :inactive-value="1"
              :before-change="() => false"
              :disabled="true"
            />
          </template>
        </template>
      </common-table>
    </div>

    <w-dialog
      ref="willNumDialogRef"
      title="报名用户列表"
      width="68%"
      top="15vh"
      :hideFooter="true"
    >
      <common-table
        ref="willNumTableRef"
        tableHeight="calc(100vh - 360px)"
        :ischeck="false"
        :ispaging="true"
        :apiName="VipApi.registerList"
        :columns="willNumColumns"
        :extraParame="{ ma_id: rowId }"
      >
      </common-table>
    </w-dialog>
    <!--图册 弹框 -->
    <w-dialog
      ref="photoDialog"
      class="photo-dialog"
      title="活动图册"
      width="40%"
      btnWidth="140px"
      top="10vh"
      v-loading.fullscreen.lock="fullLoading"
      @wConfirm="photoDialog.close()"
      :hideFooter="true"
    >
      <template v-for="(el, index) in albumList" :key="index">
        <div class="name">{{ el.sft_name }}</div>
        <img-upload
          :uploadTitle="el.sft_name"
          :limit="1"
          :suggestText="
            el.is_cover == 2 ? el.sft_cover_pic_size_text : el.sft_pic_size_text
          "
          :fileList="el.is_cover == 2 ? el.cover_files : el.files"
          @uploadFile="uploadFile($event, el, el.is_cover)"
          @deleteFile="deleteFile"
        ></img-upload>
        <br />
      </template>
    </w-dialog>
    <ActivityDialog
      ref="activityDialog"
      @submit="handleSubmit"
    ></ActivityDialog>

    <!-- 退款审核员设置 -->
    <refundAuditor ref="refAuditor"></refundAuditor>
  </div>
</template>

<script setup>
import { ref, onMounted, nextTick, computed, watch } from "vue";
import { VipApi, BasicApi } from "@/plugins/api.js";
import { ElMessage } from "element-plus";
import imgUpload from "@/components/img-upload/img-upload.vue";
import { useStore } from "vuex";
import ActivityDialog from "../components/ActivityDialog.vue";
const store = useStore();
const menuTokens = computed(() => store.state.menuToken.menuTokens);
const authData = ref([]);
watch(
  () => menuTokens.value,
  (data) => {
    if (data.length) {
      authData.value = data;
    }
  },
  {
    deep: true,
    immediate: true,
  }
);
onMounted(() => {
  getProjectData();
  getVipActivityType(2);
  tableRef.value.tableLoad();
});

const getVipActivityType = (val) => {
  VipApi.vipActivityType({ type: val }).then((res) => {
    if (res.Code === 200) {
      filters.value[2].options = res.Data ? res.Data : [];
    } else {
      let msg = res.Message ? res.Message : "操作失败！";
      ElMessage.error(msg);
    }
  });
};
const getProjectData = () => {
  BasicApi.projectSelect().then((res) => {
    if (res.Code === 200) {
      filters.value[0].options = res.Data ? res.Data : [];
    } else {
      let msg = res.Message ? res.Message : "获取项目数据失败！";
      ElMessage.error(msg);
    }
  });
};

const projectChange = (obj) => {
  filters.value[1].value = "";
  filters.value[1].options = [];
  tableRef.value.tableLoad();
  if (obj.value) {
    BasicApi.getScenicByProject({ pid: obj.value }).then((res) => {
      if (res.Code === 200) {
        filters.value[1].options = res.Data ? res.Data : [];
      } else {
        let msg = res.Message ? res.Message : "获取景区数据失败！";
        ElMessage.error(msg);
      }
    });
  }
};

const filters = ref([
  {
    filterType: "select",
    name: "pid",
    value: "",
    placeholder: "请选择项目",
    options: [],
    lab: "p_name",
    val: "p_id",
    action: "projectChange",
  },
  {
    filterType: "select",
    name: "s_id",
    value: "",
    placeholder: "请选择景区",
    options: [],
    val: "s_id",
    lab: "s_name",
  },
  {
    filterType: "select",
    name: "ma_tids",
    value: "",
    placeholder: "请选择活动类型",
    options: [],
    val: "mat_id",
    lab: "mat_name",
  },
]);
/** 表格对象 */
const tableRef = ref(null);

/** 表格配置数据 */
const tableColumns = ref([
  {
    prop: "ma_id",
    label: "ID",
    color: "--text-third-color",
    minWidth: 40,
  },
  {
    prop: "belong_name",
    label: "所属景区",
    minWidth: 360,
  },
  {
    prop: "ma_name",
    label: "活动名称",
    minWidth: 100,
  },
  {
    color: "--text-third-color",
    prop: "mat_first_name",
    label: "所属模块",
  },
  {
    prop: "mat_second_name",
    label: "活动类型",
    color: "--text-third-color",
    minWidth: 120,
  },
  {
    prop: "ma_stock",
    label: "库存",
    color: "--text-third-color",
    minWidth: 70,
  },
  {
    prop: "sold_number",
    label: "已售",
    color: "--warning-color",
    minWidth: 70,
  },
  {
    prop: "remain_number",
    label: "剩余",
    minWidth: 80,
  },

  {
    prop: "ma_stime",
    prop2: "ma_etime",
    label: "活动周期",
    color: "--text-third-color",
    type: "connect",
    connectText: "至",
    minWidth: 210,
  },

  {
    prop: "ma_status",
    type: "switch",
    label: "活动状态",
    token: "n_JefTSd3neGAJ6okmkfJUkQCSdPKq",
    minWidth: 80,
  },
  {
    type: "customRender",
    prop: "refund_status",
    label: "退款审核",
    minWidth: 80,
  },

  {
    type: "block",
    prop: "register_number",
    label: "报名人数",
    active: "vipClick",
    minWidth: 80,
    token: "n_SUVcIQiT2eq8t0REdUSe8jCsDBpH",
  },
  {
    type: "block",
    prop: "file_number",
    label: "图册",
    active: "albumClick",
    minWidth: 50,
    token: "n_by4mZi1wt0xC9YdqfsanjRT2Jc06",
  },
  {
    prop: "creator",
    label: "创建人",
    color: "--text-third-color",
    minWidth: 70,
  },
  {
    prop: "ma_ctime",
    label: "创建时间",
    color: "--text-third-color",
    minWidth: 160,
  },
  {
    type: "operation",
    prop: "",
    label: "操作",
    minWidth: 200,
    bottons: [
      {
        name: "编辑",
        action: "edit",
        token: "n_55P7koc9fBKU2rXOYIRv4s3tjG98",
      },
      {
        name: "审核员",
        action: "verifier",
        token: "n_VFHeVOOCCVusB7eVFYGt0KcCbn",
        className: "theme-font-btn",
      },
    ],
  },
]);
function changeActivityStatus(row) {
  let data = {
    ma_id: row.ma_id,
    ma_status: row.ma_status == 1 ? 2 : 1,
  };
  VipApi.setActivityStatus(data).then((res) => {
    if (res.Code === 200) {
      ElMessage.success("操作成功！");
      tableRef.value.tableLoad();
    } else {
      let msg = res.Message ? res.Message : "操作失败！";
      ElMessage.error(msg);
    }
  });
}
const photoDialog = ref(null);
/** 多文件上传列表 */
const mulFileObj = ref({
  ma_id: "",
  sft_id: "",
  file_type: "",
  files: [],
  cover_files: [],
});
/** 是否正在上传图片 */
const fullLoading = ref(false);
// 图册
const albumList = ref([]);
/** 获取景区图册数据 */
const getScenicImgs = () => {
  store.dispatch("getQiniuData");

  VipApi.getPhotos({ ma_id: currentRow.value.ma_id }).then((res) => {
    if (res.Code === 200) {
      albumList.value = res.Data || [];
      photoDialog.value.show();
    } else {
      let msg = res.Message ? res.Message : "获取景区图册数据失败！";
      ElMessage.error(msg);
    }
  });
};
/** 文件上传 */
const uploadFile = (data, obj, isCover) => {
  if (authData.value.indexOf("n_Cj6TFO6mB3tNsMGmBD8s9pBMw5Dl") != -1) {
    fullLoading.value = true;
    mulFileObj.value.ma_id = currentRow.value.ma_id;
    mulFileObj.value.sft_id = obj.sft_id;
    mulFileObj.value.file_type = 1;
    if (isCover == 2) {
      mulFileObj.value.cover_files.push(data.key);
    } else {
      mulFileObj.value.files.push(data.key);
    }
    // 去抖上传
    debounce(uploadSubmit, 300);
  } else {
    ElMessage.warning("您没有操作的权限！");
  }
};
/** 定时器 id */
const timer = ref(null);
/** 去抖函数 */
const debounce = (fn, delay) => {
  if (timer.value) {
    clearTimeout(timer.value);
  }
  timer.value = setTimeout(fn, delay);
};
/** 上传提交 */
const uploadSubmit = () => {
  VipApi.addPhotos(mulFileObj.value).then((res) => {
    if (res.Code === 200) {
      ElMessage.success("上传成功！");
      // 获取一次图册数据
      getScenicImgs();
      tableRef.value.tableLoad();
    } else {
      let msg = res.Message ? res.Message : "上传失败！";
      ElMessage.error(msg);
    }
    fullLoading.value = false;
  });
  mulFileObj.value = {
    ma_id: "",
    sft_id: "",
    file_type: "",
    files: [],
    cover_files: "",
  };
};
/** 文件删除 */
const deleteFile = (data) => {
  if (authData.value.indexOf("n_1CMKVN8SXkYLyBtwOMz0yq8UNUPF") != -1) {
    VipApi.delPhotos({ sf_id: data.sf_id }).then((res) => {
      if (res.Code === 200) {
        ElMessage.success("图片删除成功！");
        // 获取一次图册数据
        getScenicImgs();
        tableRef.value.tableLoad();
      } else {
        let msg = res.Message ? res.Message : "图片删除失败！";
        ElMessage.error(msg);
      }
    });
  } else {
    ElMessage.warning("您没有删除权限！");
  }
};
const currentRow = ref(null);
/** 点击图册处理 */
const albumClick = (row) => {
  currentRow.value = row;
  getScenicImgs();
};
const willNumColumns = ref([
  {
    prop: "m_realname",
    label: "用户名字",
  },
  {
    prop: "m_mobile",
    label: "联系方式",
    color: "--text-third-color",
  },
]);

const willNumDialogRef = ref(null);
const willNumTableRef = ref(null);
const rowId = ref(null);
function openWillNumDialog(row) {
  willNumDialogRef.value.show();
  rowId.value = row.ma_id;
  nextTick(() => {
    // 获取景区入口列表数据
    willNumTableRef.value.tableLoad();
  });
}
const activityDialog = ref(null);
const openDialog = (row) => {
  activityDialog.value.openDialog(row.ma_id);
};
const handleSubmit = (data) => {
  let url = data.ma_id ? "editVipActivity" : "addVipActivity";
  VipApi[url](data).then((res) => {
    activityDialog.value.closeDialogLoading();
    if (res.Code === 200) {
      ElMessage.success(`操作成功！`);
      activityDialog.value.closeDialog();
      tableRef.value.tableLoad();
    } else {
      let msg = res.Message ? res.Message : `操作失败！`;
      ElMessage.error(msg);
    }
  });
};
/**
 * 
 * 退款审核开关事件
 * 
 * **/
function refundStatusChange(row) {
  VipApi.setActiveRefundStatus({ ma_id: row.ma_id, ma_refund_status: row.ma_refund_status == 1 ? 2 : 1 }).then((res) => {
    if (res.Code === 200) {
      ElMessage.success("操作成功！");
      tableRef.value.tableLoad();
    } else {
      let msg = res.Message ? res.Message : "操作失败！";
      ElMessage.error(msg);
    }
  });
}
const refAuditor = ref(null);
/**
 * 
 * 打开审核员弹框
 * 
 * **/
function openVerifier(row) {
  refAuditor.value.openDialog('member', row,);
}
</script>
<style lang="scss">
.activity-container {
  font-family: "Source Han Sans CN";
  .content {
    padding: 15px 20px 20px;
  }
}
</style>
