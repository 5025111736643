<template>
  <div class="activity-dialog">
    <w-dialog
      ref="dialogRef"
      class="vip-dialog"
      :title="ruleForm.ma_id ? '编辑活动' : '新增活动'"
      width="60%"
      btnWidth="140px"
      top="10vh"
      :confirmText="ruleForm.ma_id ? '确认编辑' : '确认新增'"
      @wConfirm="handleSure"
    >
      <el-form
        class="add-form"
        ref="formRef"
        :model="ruleForm"
        :rules="formRules"
        labelPosition="top"
      >
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="所属项目" prop="ma_pid">
              <el-select
                v-model="ruleForm.ma_pid"
                clearable
                placeholder="请选择所属项目"
                @change="changeFormProject"
                :disabled="ruleForm.ma_id ? true : false"
              >
                <el-option
                  :label="item.p_name"
                  :value="item.p_id"
                  v-for="item in projectOptions"
                  :key="item.p_id"
                ></el-option>
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="8">
            <el-form-item label="所属景区" prop="ma_sid">
              <el-select
                :disabled="!ruleForm.ma_pid || ruleForm.ma_id ? true : false"
                v-model="ruleForm.ma_sid"
                clearable
                placeholder="请选择所属景区"
              >
                <el-option
                  :label="item.s_name"
                  :value="item.s_id"
                  v-for="item in scenicOptions"
                  :key="item.s_id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col
            :span="8"
            v-if="
              ruleForm.activityType &&
              ruleForm.activityType.length &&
              ruleForm.activityType[1] !== 3
            "
          >
            <el-form-item label="所属社群" prop="ma_mcid">
              <el-select
                :disabled="!ruleForm.ma_pid || ruleForm.ma_id ? true : false"
                v-model="ruleForm.ma_mcid"
                clearable
                placeholder="请选择所属社群"
              >
                <el-option
                  :label="item.mc_name"
                  :value="item.mc_id"
                  v-for="item in communityOptions"
                  :key="item.mc_id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="上架周期" prop="putawayDate">
              <el-date-picker
                v-model="ruleForm.putawayDate"
                type="daterange"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                value-format="YYYY-MM-DD"
                :clearable="false"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="活动类型" prop="activityType">
              <el-cascader
                :disabled="ruleForm.ma_id ? true : false"
                placeholder="请选择活动类型"
                v-model="ruleForm.activityType"
                :options="activityTypeData"
                :props="{
                  children: 'children',
                  label: 'mat_name',
                  value: 'mat_id',
                }"
                clearable
                collapse-tags
                :show-all-levels="false"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="活动周期" prop="useDate">
              <el-date-picker
                v-model="ruleForm.useDate"
                type="daterange"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                value-format="YYYY-MM-DD"
                :clearable="false"
                @change="changeTime"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="活动名称" prop="ma_name">
              <el-input
                v-model="ruleForm.ma_name"
                clearable
                placeholder="请输入活动名称"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="活动副标题" prop="ma_subtitle">
              <el-input
                v-model="ruleForm.ma_subtitle"
                placeholder="请输入活动副标题"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col
            :span="8"
            v-if="ruleForm.activityType && ruleForm.activityType.length"
          >
            <el-form-item
              label="收费金额"
              prop="ma_money"
              v-if="ruleForm.activityType[1] != 4"
            >
              <el-input
                v-model="ruleForm.ma_money"
                clearable
                placeholder="请输入收费金额"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>

        <el-row
          :gutter="20"
          v-if="
            ruleForm.activityType &&
            ruleForm.activityType.length &&
            ruleForm.activityType[1] === 3
          "
        >
          <el-col :span="8">
            <el-form-item label="限购数量" prop="ma_limit_num">
              <el-input
                type="number"
                v-model="ruleForm.ma_limit_num"
                clearable
                placeholder="请输入"
              >
                <template #prepend>每人限购</template>
                <template #append>份</template></el-input
              >
            </el-form-item></el-col
          >
          <el-col :span="8">
            <el-form-item label="总库存量" prop="ma_stock">
              <el-input
                type="number"
                v-model="ruleForm.ma_stock"
                clearable
                placeholder="请输入总库存量"
              >
              </el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24"
            ><el-form-item label="活动介绍" prop="ma_introduce">
              <div
                ref="noticeEditorElem"
                class="editor"
              ></div> </el-form-item></el-col
        ></el-row>
        <template
          v-if="
            ruleForm.activityType &&
            ruleForm.activityType.length &&
            ruleForm.activityType[1] === 3 &&
            ruleForm.ma_pid &&
            ruleForm.useDate &&
            ruleForm.useDate.length
          "
        >
          <div v-if="allBindHotel.length || allBindScenic.length">
            <el-divider />关联商品
          </div>
          <br />

          <el-row :gutter="20" v-for="(el, index) in allBindHotel" :key="index">
            <el-col :span="24" class="bind-box">
              <div
                class="bind-item"
                :class="
                  selectBindHotel &&
                  selectBindHotel.length &&
                  selectBindHotel[0] &&
                  selectBindHotel[0].id == el.id
                    ? 'bind-active'
                    : ''
                "
                @click="bindItem(1, el)"
              >
                <div class="bind-info">
                  <span class="name">{{ el.name }}</span>
                  <span class="price">￥{{ el.price }}</span>
                </div>
              </div>
              <div
                v-if="
                  selectBindHotel &&
                  selectBindHotel.length &&
                  selectBindHotel[0] &&
                  selectBindHotel[0].id == el.id
                "
                class="input-box"
              >
                <el-input
                  class="day"
                  type="number"
                  v-model="el.day"
                  clearable
                  placeholder="请输入"
                  @blur="handleBlurDay($event, el)"
                >
                  <template #prepend>入住</template>
                  <template #append>晚</template></el-input
                >
                <el-input
                  placeholder="请输入"
                  v-model="el.lastPrice"
                  clearable
                  type="number"
                  @blur="handleBlurPrice($event, 1, el)"
                >
                  <template #prepend>分成</template>
                  <template #append>元</template></el-input
                >
                <el-icon><Check /></el-icon>
              </div>
            </el-col>
          </el-row>
          <br />
          <el-row
            :gutter="20"
            v-for="(el, index) in allBindScenic"
            :key="index"
          >
            <el-col :span="24" class="bind-box">
              <div
                @click.self="bindItem(2, el)"
                class="bind-item"
                :class="
                  selectBindScenic &&
                  selectBindScenic.length &&
                  selectBindScenic[0] &&
                  selectBindScenic[0].id == el.id
                    ? 'bind-active'
                    : ''
                "
              >
                <div class="bind-info">
                  <span class="name">{{ el.name }}</span>
                  <span class="price">￥{{ el.price }}</span>
                </div>
              </div>
              <div
                v-if="
                  selectBindScenic &&
                  selectBindScenic.length &&
                  selectBindScenic[0] &&
                  selectBindScenic[0].id == el.id
                "
                class="input-box"
              >
                <el-input
                  placeholder="请输入"
                  v-model="el.lastPrice"
                  clearable
                  type="number"
                  @blur="handleBlurPrice($event, 2, el)"
                >
                  <template #prepend>分成</template>
                  <template #append>元</template></el-input
                >
                <el-icon><Check /></el-icon>
              </div>
            </el-col>
          </el-row>
        </template>
        <template v-if="ruleForm.activityType && ruleForm.activityType.length">
          <template
            v-if="
              ruleForm.activityType[1] === 4 || ruleForm.activityType[1] === 6
            "
            ><el-divider />
            <div class="mt-10">报名信息</div>
            <el-row :gutter="20">
              <el-col :span="8" class="regist-info">
                <el-input
                  v-model="registTitle"
                  placeholder="请输入报名信息标题"
                >
                </el-input>
                <div class="add-btn" @click="handleAdd">添加</div>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8" v-for="el in ruleForm.ma_registerArr" :key="el">
                <div class="regist-item">
                  <div>{{ el }}</div>
                  <el-icon @click="handleDelete(el)"><CloseBold /></el-icon>
                </div>
              </el-col>
            </el-row>
          </template>
        </template>
        <el-divider />
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="" prop="ma_home_show">
              <div class="mr-4">首页展示</div>
              <el-switch v-model="ruleForm.ma_home_show" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="" prop="ma_module_show">
              <div class="mr-4">模块页展示</div>
              <el-switch v-model="ruleForm.ma_module_show" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </w-dialog>
  </div>
</template>

<script>
import { ref, onMounted, watch, nextTick, computed } from "vue";
import { ElMessage } from "element-plus";
import { VipApi, BasicApi } from "@/plugins/api.js";
import { CloseBold, Check } from "@element-plus/icons-vue";
import Editor from "wangeditor";
import { useStore } from "vuex";
import * as Qiniu from "qiniu-js";

export default {
  emits: ["submit"],
  components: {
    Check,
    CloseBold,
  },
  setup(props, { emit }) {
    onMounted(() => {
      scenicOptions.value = [];
      getProjectData();
      getVipActivityType(2);
      getCommunity();
    });
    const store = useStore();
    const qiniuData = computed(() => store.state.Config.qiniuData);
    const projectOptions = ref([]);
    const getProjectData = () => {
      BasicApi.projectSelect().then((res) => {
        if (res.Code === 200) {
          projectOptions.value = res.Data;
        } else {
          let msg = res.Message ? res.Message : "获取项目数据失败！";
          ElMessage.error(msg);
        }
      });
    };
    const activityTypeData = ref([]);
    const getVipActivityType = (val) => {
      VipApi.vipActivityType({ type: val }).then((res) => {
        if (res.Code === 200) {
          activityTypeData.value = res.Data;
        } else {
          let msg = res.Message ? res.Message : "操作失败！";
          ElMessage.error(msg);
        }
      });
    };
    const communityOptions = ref([]);
    const getCommunity = () => {
      BasicApi.getCommunity().then((res) => {
        if (res.Code === 200) {
          communityOptions.value = res.Data;
        } else {
          let msg = res.Message;
          ElMessage.error(msg);
        }
      });
    };
    const ruleForm = ref({
      ma_pid: "",
      ma_sid: "",
      ma_mcid: "",
      activityType: [],
      useDate: [],
      putawayDate: [],
      ma_name: "",
      ma_subtitle: "",
      ma_money: "",
      ma_limit_num: "",
      ma_stock: "",
      ma_introduce: "",
      images: [],
      ma_home_show: true,
      ma_module_show: true,
      ma_registerArr: [],
    });
    const dialogRef = ref(null);
    const commonFormRules = {
      ma_pid: [
        {
          required: true,
          message: "请选择所属项目",
          trigger: "blur",
        },
      ],
      ma_sid: [
        {
          required: true,
          message: "请选择所属景区",
          trigger: "blur",
        },
      ],
      activityType: [
        {
          required: true,
          message: "请选择活动类型",
          trigger: "blur",
        },
      ],
      useDate: [
        {
          required: true,
          message: "请选择活动周期",
          trigger: "blur",
        },
      ],
      putawayDate: [
        {
          required: true,
          message: "请选择上架周期",
          trigger: "blur",
        },
      ],
      ma_name: [
        {
          required: true,
          message: "请输入活动名称",
          trigger: "blur",
        },
      ],
      ma_subtitle: [
        {
          required: true,
          message: "请输入活动副标题",
          trigger: "blur",
        },
      ],
      ma_introduce: [
        {
          required: true,
          message: "请输入活动介绍",
          trigger: "blur",
        },
      ],
    };
    const formRules = ref({});

    // 表单项目变化选景区
    const scenicOptions = ref([]);
    const changeFormProject = () => {
      ruleForm.value.ma_sid = "";
    };
    /** 预定须知 富文本容器 */
    const noticeEditorElem = ref(null);
    /** 预定须知 富文本实例对象 */
    const noticeEditor = ref(null);
    // 预定须知 富文本初始化
    const noticeEditorInit = () => {
      noticeEditor.value = new Editor(noticeEditorElem.value);
      noticeEditor.value.config.colors = [
        "#000000",
        "#eeece0",
        "#1c487f",
        "#4d80bf",
        "#0072f6",
        "#1a1a1a",
        "#FFB900",
        "#EB5774",
        "#f2f2f2",
        "#666666",
        "#999999",
        "#cccccc",
      ];
      // 配置 onchange 回调函数
      noticeEditor.value.config.onchange = (newHtml) => {
        ruleForm.value.ma_introduce = newHtml;
        formRef.value.validateField("ma_introduce");
      };
      // 自定义菜单配置
      noticeEditor.value.config.menus = [
        "head",
        "bold",
        "fontSize",
        "fontName",
        "italic",
        "underline",
        "strikeThrough",
        "indent",
        "lineHeight",
        "foreColor",
        "justify",
        "quote",
        "splitLine",
        "image",
        "undo",
        "redo",
      ];
      // 限制上传图片格式
      noticeEditor.value.config.uploadImgAccept = [
        "jpg",
        "jpeg",
        "png",
        "gif",
        "bmp",
      ];
      noticeEditor.value.config.qiniu = true;
      noticeEditor.value.config.showLinkImg = false; // 开启本地上传图片(这是后端上传链接)
      const upToken = qiniuData.value.token;
      const domain = qiniuData.value.http_domain;
      noticeEditor.value.config.customUploadImg = (file) => {
        if (file.length) {
          file.forEach((el) => {
            const myDate = new Date();
            const year_month = myDate.toLocaleDateString().replace(/\//g, ""); //当前时间日期
            const timeData = Date.parse(new Date()); //当前时间时时间戳
            const random = Math.floor(Math.random() * (1 - 1000) + 1000); //1-1000随机数
            const randoms = Math.floor(Math.random() * (1 - 100) + 100); //1-100随机数
            const filename = el.name;
            const index = filename.lastIndexOf(".");
            const suffix = filename.substr(index + 1);
            const key = `vip-activity-${year_month}-${timeData}${random}-${randoms}.${suffix}`;
            const config = {
              // useCdnDomain: true, //表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
              cdnUphost: domain,
            };
            const putExtra = {
              fname: "", //文件原文件名
              params: {}, //用来放置自定义变量
              mimeType: null, //用来限制上传文件类型，为 null 时表示不对文件类型限制；限制类型放到数组里： ["image/png", "image/jpeg", "image/gif"]
            };
            const observable = Qiniu.upload(el, key, upToken, putExtra, config);
            observable.subscribe({
              error: () => {},
              complete: (res) => {
                const sourceLink = domain + res.key;
                noticeEditor.value.cmd.do(
                  "insertHtml",
                  '<img src="' + sourceLink + '" style="max-width:100%;"/>'
                );
                ruleForm.value.images.push({
                  filename: filename,
                  qiniu_key: res.key,
                  size: el.size,
                });
              },
            });
          });
        }
      };

      noticeEditor.value.config.height = 300;
      // 创建富文本实例
      noticeEditor.value.create();
      noticeEditor.value.txt.html(ruleForm.value.ma_introduce);
    };
    watch(
      () => ruleForm.value.ma_pid,
      (v) => {
        if (v) {
          BasicApi.getScenicByProject({ pid: v }).then((res) => {
            if (res.Code === 200) {
              scenicOptions.value = res.Data ? res.Data : [];
            } else {
              let msg = res.Message ? res.Message : "获取景区数据失败！";
              ElMessage.error(msg);
            }
          });
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );

    const allBindHotel = ref([]);
    const selectBindHotel = ref([]);
    const allBindScenic = ref([]);
    const selectBindScenic = ref([]);
    function handleBlurDay(el, data) {
      //酒店
      const hotelIndex = selectBindHotel.value.findIndex(
        (item) => item.id == data.id
      );
      hotelIndex != -1
        ? (selectBindHotel.value[hotelIndex]["day"] = data.day)
        : "";
    }
    function handleBlurPrice(el, type, data) {
      if (type == 1) {
        //酒店
        const hotelIndex = selectBindHotel.value.findIndex(
          (item) => item.id == data.id
        );
        hotelIndex != -1
          ? (selectBindHotel.value[hotelIndex]["lastPrice"] = data.lastPrice)
          : "";
      } else {
        //景区
        const scenicIndex = selectBindScenic.value.findIndex(
          (item) => item.id == data.id
        );
        scenicIndex != -1
          ? (selectBindScenic.value[scenicIndex]["lastPrice"] = data.lastPrice)
          : "";
      }
    }

    function bindItem(type, data) {
      if (type == 1) {
        const hotelIndex = selectBindHotel.value.findIndex(
          (item) => item.id == data.id
        );
        if (hotelIndex != -1) {
          selectBindHotel.value = [];
        } else {
          selectBindHotel.value = [data];
        }
      } else if (type == 2) {
        const scenicIndex = selectBindScenic.value.findIndex(
          (item) => item.id == data.id
        );
        if (scenicIndex != -1) {
          selectBindScenic.value = [];
        } else {
          selectBindScenic.value = [data];
        }
      }
    }

    function vipActivityBind(val, data) {
      const parmas = {
        pid: ruleForm.value.ma_pid,
        type: val,
        s_time: ruleForm.value.useDate[0],
        e_time: ruleForm.value.useDate[1],
      };
      VipApi.vipActivityBind(parmas).then((res) => {
        if (res.Code === 200) {
          if (val == 1) {
            if (res.Data && res.Data.length) {
              allBindHotel.value = res.Data;
            } else {
              allBindHotel.value = [];
              selectBindHotel.value = [];
            }
            data &&
              data.length &&
              data.forEach((el) => {
                selectBindHotel.value.push({
                  lastPrice: el.mab_money,
                  id: el.mab_hpid,
                  day: el.mab_hp_day,
                  name: el.name,
                });

                if (
                  allBindHotel.value.length &&
                  allBindHotel.value.findIndex(
                    (item) => item.id == el.mab_hpid
                  ) != -1
                ) {
                  allBindHotel.value[
                    allBindHotel.value.findIndex(
                      (item) => item.id == el.mab_hpid
                    )
                  ]["lastPrice"] = el.mab_money;
                  allBindHotel.value[
                    allBindHotel.value.findIndex(
                      (item) => item.id == el.mab_hpid
                    )
                  ]["day"] = el.mab_hp_day;
                }
              });
          } else {
            if (res.Data && res.Data.length) {
              allBindScenic.value = res.Data;
            } else {
              allBindScenic.value = [];
              selectBindScenic.value = [];
            }
            data &&
              data.length &&
              data.forEach((el) => {
                selectBindScenic.value.push({
                  lastPrice: el.mab_money,
                  id: el.mab_stid,
                  name: el.name,
                });

                allBindScenic.value.length &&
                allBindScenic.value.findIndex(
                  (item) => item.id == el.mab_stid
                ) != -1
                  ? (allBindScenic.value[
                      allBindScenic.value.findIndex(
                        (item) => item.id == el.mab_stid
                      )
                    ]["lastPrice"] = el.mab_money)
                  : "";
              });
          }
        } else {
          let msg = res.Message ? res.Message : "操作失败！";
          ElMessage.error(msg);
        }
      });
    }
    function changeTime() {
      vipActivityBind(1);
      vipActivityBind(2);
    }
    // 活动类型
    watch(
      () => ruleForm.value.activityType,
      (data) => {
        if (data && data.length) {
          if (data[1] == 3) {
            // 促销
            formRules.value = {
              ...commonFormRules,
              ma_money: [
                {
                  required: true,
                  message: "请输入收费金额",
                  trigger: "blur",
                },
              ],
              ma_limit_num: [
                {
                  required: true,
                  message: "请输入限购数量",
                  trigger: "blur",
                },
              ],
              ma_stock: [
                {
                  required: true,
                  message: "请输入总库存量",
                  trigger: "blur",
                },
              ],
            };
          } else if (data[1] == 6) {
            // 报名+收费
            formRules.value = {
              ...commonFormRules,
              ma_money: [
                {
                  required: true,
                  message: "请输入收费金额",
                  trigger: "blur",
                },
              ],
              ma_mcid: [
                {
                  required: true,
                  message: "请选择所属社群",
                  trigger: "blur",
                },
              ],
            };
          } else if (data[1] == 4) {
            // 仅报名
            formRules.value = {
              ...commonFormRules,
              ma_mcid: [
                {
                  required: true,
                  message: "请选择所属社群",
                  trigger: "blur",
                },
              ],
            };
          } else if (data[1] == 5) {
            // 仅收费
            formRules.value = {
              ...commonFormRules,
              ma_money: [
                {
                  required: true,
                  message: "请输入收费金额",
                  trigger: "blur",
                },
              ],
              ma_mcid: [
                {
                  required: true,
                  message: "请选择所属社群",
                  trigger: "blur",
                },
              ],
            };
          }
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );
    // 添加报名信息
    const registTitle = ref("");
    function handleAdd() {
      if (ruleForm.value.ma_registerArr.indexOf(registTitle.value) === -1) {
        ruleForm.value.ma_registerArr.push(registTitle.value);
        registTitle.value = "";
      }
    }
    // 删除报名信息
    function handleDelete(el) {
      const index = ruleForm.value.ma_registerArr.findIndex(
        (item) => item === el
      );
      ruleForm.value.ma_registerArr.splice(index, 1);
    }
    function openDialog(id) {
      formRules.value = commonFormRules;
      selectBindHotel.value = [];
      selectBindScenic.value = [];
      registTitle.value = "";

      if (id) {
        //编辑
        VipApi["vipActivityDetail"]({ ma_id: id }).then((res) => {
          ruleForm.value = {
            ma_id: res.Data.ma_id,
            ma_pid: res.Data.ma_pid,
            ma_sid: res.Data.ma_sid,
            ma_mcid: res.Data.ma_mcid,
            activityType: [res.Data.ma_tid, res.Data.ma_tids],
            useDate: [res.Data.ma_stime, res.Data.ma_etime],
            putawayDate: [res.Data.ma_launch_stime, res.Data.ma_launch_etime],
            ma_name: res.Data.ma_name,
            ma_subtitle: res.Data.ma_subtitle,
            ma_money: res.Data.ma_money,
            ma_limit_num: res.Data.ma_limit_num,
            ma_stock: res.Data.ma_stock,
            ma_introduce: res.Data.ma_introduce,
            images: res.Data.images || [],
            ma_home_show: res.Data.ma_home_show == 1 ? true : false,
            ma_module_show: res.Data.ma_module_show == 1 ? true : false,
            ma_registerArr: res.Data.ma_register
              ? res.Data.ma_register.split(",")
              : [],
          };
          if (res.Data.bind.hp.length) {
            // 绑定的酒店
            vipActivityBind(1, res.Data.bind.hp);
          }
          if (res.Data.bind.st.length) {
            // 绑定的景点
            vipActivityBind(2, res.Data.bind.st);
          }
          getVipActivityType(1);
          dialogRef.value.show();
          nextTick(() => {
            store.dispatch("getQiniuData");
            noticeEditorInit();
          });
        });
      } else {
        ruleForm.value = {
          ma_pid: "",
          ma_sid: "",
          ma_mcid: "",
          activityType: [],
          useDate: [],
          putawayDate: [],
          ma_name: "",
          ma_subtitle: "",
          ma_money: "",
          ma_limit_num: "",
          ma_stock: "",
          ma_introduce: "",
          images: [],
          ma_home_show: true,
          ma_module_show: true,
          ma_registerArr: [],
        };
        getVipActivityType(1);
        dialogRef.value.show();
        nextTick(() => {
          noticeEditorInit();
        });
      }
    }
    function closeDialog() {
      dialogRef.value.close();
    }
    function closeDialogLoading() {
      dialogRef.value.isLoading = false;
    }
    const formRef = ref(null);
    function handleSure() {
      if (formRef.value) {
        formRef.value.validate((valid) => {
          if (valid) {
            let parmas = {
              ma_pid: ruleForm.value.ma_pid,
              ma_sid: ruleForm.value.ma_sid,
              ma_name: ruleForm.value.ma_name,
              ma_subtitle: ruleForm.value.ma_subtitle,
              ma_stime: ruleForm.value.useDate[0],
              ma_etime: ruleForm.value.useDate[1],
              ma_launch_stime: ruleForm.value.putawayDate[0],
              ma_launch_etime: ruleForm.value.putawayDate[1],
              ma_introduce: ruleForm.value.ma_introduce,
              images: ruleForm.value.images,
              ma_tid: ruleForm.value.activityType[0],
              ma_tids: ruleForm.value.activityType[1],
              ma_home_show: ruleForm.value.ma_home_show ? 1 : 2,
              ma_module_show: ruleForm.value.ma_module_show ? 1 : 2,
            };
            if (ruleForm.value.activityType[1] == 3) {
              // 促销类
              const lastBind = [];
              if (
                !selectBindHotel.value.length &&
                !selectBindScenic.value.length
              ) {
                ElMessage.warning(`请设置需要关联的商品！`);
                return false;
              }

              if (selectBindHotel.value.length) {
                for (let i = 0; i < selectBindHotel.value.length; i++) {
                  if (!selectBindHotel.value[i].lastPrice) {
                    ElMessage.warning(
                      `请设置${selectBindHotel.value[i].name}的分成金额！`
                    );
                    return false;
                  }
                  if (!selectBindHotel.value[i].day) {
                    ElMessage.warning(
                      `请设置${selectBindHotel.value[i].name}的入住时长！`
                    );
                    return false;
                  }
                  lastBind.push({
                    mab_hpid: selectBindHotel.value[i].id,
                    mab_money: selectBindHotel.value[i].lastPrice,
                    mab_hp_day: selectBindHotel.value[i].day,
                  });
                }
              }
              if (selectBindScenic.value.length) {
                for (let i = 0; i < selectBindScenic.value.length; i++) {
                  if (!selectBindScenic.value[i].lastPrice) {
                    ElMessage.warning(
                      `请设置${selectBindScenic.value[i].name}的分成金额！`
                    );
                    return false;
                  }
                  lastBind.push({
                    mab_stid: selectBindScenic.value[i].id,
                    mab_money: selectBindScenic.value[i].lastPrice,
                  });
                }
              }
              parmas["ma_stock"] = ruleForm.value.ma_stock;
              parmas["ma_limit_num"] = ruleForm.value.ma_limit_num;
              parmas["ma_money"] = ruleForm.value.ma_money;
              parmas["bind"] = lastBind;
            } else if (ruleForm.value.activityType[1] == 6) {
              // 报名+收费
              if (ruleForm.value.ma_registerArr.length) {
                parmas["ma_register"] = ruleForm.value.ma_registerArr.join(",");
              }
              parmas["ma_money"] = ruleForm.value.ma_money;
              parmas["ma_mcid"] = ruleForm.value.ma_mcid;
            } else if (ruleForm.value.activityType[1] == 4) {
              // 仅报名
              if (ruleForm.value.ma_registerArr.length) {
                parmas["ma_register"] = ruleForm.value.ma_registerArr.join(",");
              }
              parmas["ma_mcid"] = ruleForm.value.ma_mcid;
            } else if (ruleForm.value.activityType[1] == 5) {
              // 仅收费
              parmas["ma_money"] = ruleForm.value.ma_money;
              parmas["ma_mcid"] = ruleForm.value.ma_mcid;
            }
            dialogRef.value.isLoading = true;
            ruleForm.value.ma_id
              ? (parmas["ma_id"] = ruleForm.value.ma_id)
              : "";

            emit("submit", parmas);
          }
        });
      }
    }
    return {
      ruleForm,
      formRules,
      formRef,
      handleSure,
      openDialog,
      dialogRef,
      changeFormProject,
      closeDialog,
      closeDialogLoading,
      bindItem,
      handleAdd,
      handleDelete,
      scenicOptions,
      projectOptions,
      communityOptions,
      activityTypeData,
      registTitle,
      allBindScenic,
      allBindHotel,
      handleBlurPrice,
      changeTime,
      handleBlurDay,
      selectBindHotel,
      selectBindScenic,
      noticeEditorElem,
    };
  },
};
</script>

<style lang="scss">
.activity-dialog {
  .el-input-group__append,
  .el-input-group__prepend {
    border: none;
    background-color: var(--search-uncheck-bg-color) !important;
    color: var(--text-gray-color);
  }
  .el-input-group__append {
    border-left: 1px solid #e6e3e3;
  }
  .el-input-group__prepend {
    border-right: 1px solid #e6e3e3;
  }
  .mr-4 {
    margin-right: 4px;
  }
  .mt-10 {
    margin-top: 10px;
  }
  .el-form-item__content {
    display: flex;
    align-items: center;
    .editor {
      width: 100%;
    }
  }
  .bind-box {
    position: relative;
    width: 100%;
    .input-box {
      height: 30px;
      display: flex;
      align-items: center;
      position: absolute;
      right: 20px;
      bottom: 20px;
      .el-input-group__append {
        border-left: 1px solid #eee;
      }
      .el-input-group__prepend {
        border-right: 1px solid #eee;
      }
      .el-input-group__append,
      .el-input-group__prepend {
        background-color: #fafafa !important;
      }
      .day {
        margin-right: 10px;
      }
      i {
        color: var(--theme-color);
        margin-left: 10px;
      }
      .el-input {
        height: 100%;
        .el-input__inner {
          height: 100%;
          background-color: #fff;
          width: 100px;
        }
      }
    }
    .bind-item {
      border: 2px dotted var(--text-gray-color);
      padding: 20px;
      height: 50px;
      box-sizing: border-box;
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      .bind-info {
        .name {
          color: var(--text-color);
          font-weight: bold;
        }
        .price {
          color: var(--text-third-color);
        }
      }
    }
  }

  .bind-active {
    background-color: var(--search-bg-color);
    border: none !important;
    .bind-info {
      .name {
        color: var(--theme-color);
      }
      .price {
        color: var(--theme-color);
        opacity: 0.6;
      }
    }
  }
  .regist-info {
    display: flex;
    margin-bottom: 10px;
    .el-input {
      flex: 1;
    }
    > .add-btn {
      padding: 0 20px;
      display: flex;
      align-items: center;
      text-align: center;
      background-color: var(--theme-color);
      color: #fff;
      border-radius: 4px;
      cursor: pointer;
    }
  }
  .regist-item {
    padding: 8px 10px;
    border: 2px dashed #eee;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .el-icon {
      cursor: pointer;
      &:hover {
        color: var(--theme-color);
      }
    }
  }
  .vip-dialog {
    .el-dialog {
      min-width: 600px;
      .el-dialog__body {
        padding: 30px;
        .el-divider--horizontal {
          margin: 10px 0;
        }
      }
    }
  }
}
</style>
